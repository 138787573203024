@use "../mixins" as *

.sitenav
  &__link
    color: #1D1D1D
    font-size: 14px
    font-weight: 500
    letter-spacing: 5%
    text-transform: uppercase

    &_current
      color: #FF3222

.section
  width: 100%
  max-width: 1515px
  margin: 0 auto 100px
  padding: 0 40px

  @include mobile
    padding: 0 20px

  &_loading
    @include grid(25px)
    font-size: 18px
    margin-bottom: 0
    padding: 16px

  &__title
    font-size: 45px
    font-weight: 700
    margin-bottom: 50px

    @include mobile
      font-size: 30px

  &__text
    color: #373B42
    font-size: 25px
    font-weight: 500
    line-height: 1.5

    @include mobile
      font-size: 20px

.hero
  background: url(../images/bg-f-1.png) 0 / cover

  &__section
    @include grid(70px 0)
    margin-bottom: 0
    padding-top: 70px
    padding-bottom: 120px

    @include mobile
      padding-top: 20px
      row-gap: 40px

    @include desktop
      grid-template-columns: 1fr 1fr

  &__title
    margin: 0

  &__image
    width: 100%
    align-self: center

    @include desktop
      grid-column: 2
      grid-row: 1 / 4

.benefits
  @include flex(32px, wrap)
  grid-column: 1 / -1

  @include not-desktop
    justify-content: center

  &__item
    @include grid(15px)
    grid-template-areas: "icon title" "icon text"
    background: white
    border-radius: 25px
    color: #61666F
    font-size: 18px
    padding: 30px

    &::before
      content: url(../images/checkmark.svg)
      grid-area: icon

  &__title
    color: #373B42
    font-family: "Cygre", sans-serif
    font-size: 22px
    font-weight: 700

.features
  @include grid(30px)
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(380px, 30%)), 1fr))

  &__item
    background: white
    border-radius: 25px
    color: #61666F
    font-size: 18px
    padding: 50px

    @include mobile
      padding: 30px

  &__title
    color: #FF3222
    font-size: 22px
    font-weight: 700
    margin: 20px 0

  &__more
    color: #373B42
    font-size: 30px
    font-weight: 700
    place-self: center

.video
  @include grid(60px 80px)
  align-items: end
  background: url(../images/bg-index-2.png) no-repeat 0 / contain
  padding-bottom: 150px
  margin-bottom: -150px

  @include mobile
    row-gap: 40px

  @include desktop
    grid-template: "top top" "content video" "content video" 1fr "content video" "content video" / 1fr 1fr

  @include not-desktop
    grid-template: "top" "content" "video"

  &__top
    grid-area: top

  &__placeholder
    width: 100%
    grid-area: video

.parameters
  @include flex(20px)
  font-size: 18px

  @include mobile
    flex-direction: column
    align-items: center

  &__item
    @include flex(10px, center)

.note
  color: #61666F
  font-family: "Cygre", sans-serif
  font-size: 18px

.tariff
  @include flex(60px, wrap)
  justify-content: space-around
  font-size: 18px
  margin-top: 80px

  &__item
    @include flex(0, column, center)
    width: 270px

  &__icon
    margin-bottom: 40px
    flex: 1

  &__title
    font-size: 35px
    font-weight: 700
    margin-bottom: 15px

  &__subtitle
    color: #61666F

  &__price
    color: #FF3222
    font-size: 25px
    font-weight: 500
    margin: 25px 0

  &__table
    border-spacing: 0 12px
    margin-bottom: 40px
    white-space: nowrap

    td
      width: 100%

.analyze
  text-align: center
  margin-bottom: 0

  &__field
    @include flex(10px)

    @include mobile
      flex-direction: column

  & &__field
    grid-area: field

  &__input
    width: 100%
    height: 50px
    background: #FCFCFC
    border: 1px solid #EAEAEA
    border-radius: 12px
    padding: 15px 20px
    font: inherit
    flex: 1

  &__section
    @include grid(50px)
    grid-template: "field field" "image arrow" 200px "image text" / 4fr 1fr
    max-width: 1100px
    margin: 0 auto
    padding: 80px 0

    @include mobile
      gap: 20px
      grid-template: "field" "arrow" 120px "text" "image"
      padding: 40px 0

  &__image
    width: 100%
    grid-area: image

  &__arrow
    height: 100%
    grid-area: arrow
    place-self: center
