@use "../mixins" as *

.header
  @include flex(40px, center)
  justify-content: space-between
  max-width: 1700px
  margin: 0 auto
  padding: 50px 40px

  @include mobile
    padding: 40px 30px

  &__links
    @include flex
    justify-content: space-around
    flex: 1

    @include not-desktop
      display: none

  &__login
    @include flex(30px)

    @include not-desktop
      display: none

  &__burger
    background-image: url(../images/menu.svg)

    @include desktop
      display: none
