@use "../mixins" as *

.progress
  @include flex(30px, center)
  margin-top: 12px

  &__track
    height: 8px
    background: #F5F5F5
    border-radius: 99em
    flex: 1

  &__bar
    width: var(--progress)
    height: 100%
    background: var(--color)
    border-radius: inherit

  &__percent
    color: #61666F
    font-size: 14px

  &_red
    --color: #F3511F

  &_green
    --color: #81DA0B

  &_blue
    --color: #1890FF
