@use "../mixins" as *

.picker
  position: absolute
  top: 100%
  right: 0
  margin-top: 10px
  background: white
  border-radius: 6px
  box-shadow: 0 8px 30px rgba(22, 38, 52, 0.1)
  padding: 15px 20px
  visibility: hidden
  white-space: nowrap

  &-open
    position: relative

  &_share
    @include flex(6px)
