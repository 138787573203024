@use "../mixins" as *

.banner-block
  @include flex(30px, column)

  @include tablet
    flex-direction: row
    align-items: flex-start

.banner
  border-radius: 6px
  font-size: 18px
  overflow: hidden

  &_side
    text-align: center

    &_1
      background: white

    &_2
      background: black

    img
      width: 100%

  &_signup
    @include flex(30px, center)
    justify-content: center
    background: #701FF3
    color: white
    padding: 40px 30px

    @include mobile
      padding: 25px 20px

    @include not-desktop
      flex-direction: column

  &_bottom
    position: relative
    background: white

  &__inner
    padding: 50px

    @include mobile
      padding: 25px

    @include desktop
      position: relative
      max-width: 740px
      z-index: 9

  &__image
    @include desktop
      position: absolute
      top: 0
      right: 0
      height: 100%

    @include not-desktop
      width: 100%

  &__title
    font-size: 35px
    text-transform: uppercase
    margin-bottom: 18px

    @include mobile
      font-size: 28px

  &__content
    @include grid(15px)
    padding: 20px

  &__bold
    font-size: 22px
    font-weight: 700
    margin-bottom: 15px

  &__form
    @include grid(20px)
    margin-top: 32px

    @include not-mobile
      grid-template-columns: repeat(3, 1fr)

    .button
      grid-column: 1 / -1

      @include not-mobile
        justify-self: start

  &__field
    min-width: 0
    border: 1px solid #EAEAEA
    border-radius: 6px
    font: inherit
    padding: 12px

    &_dark
      background: none
      color: #EAEAEA
      text-align: center

    &::placeholder
      color: #95979A

  &__textarea
    grid-column: 1 / -1
    resize: none

  &__note
    color: #A6ACB8
    font-size: 12px
