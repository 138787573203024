@use "../mixins" as *

.footer
  @include grid(40px)
  max-width: 1620px
  background: white
  border-radius: 30px 30px 0 0
  margin: 40px auto 0
  padding: 50px

  @include desktop
    grid-template-columns: auto 1fr auto

  &__block
    @include flex(20px, column)
    justify-content: space-between

  &__links
    @include grid(30px)
    align-items: center

    @include desktop
      grid-template-columns: repeat(4, minmax(auto, 200px))

.copyright
  color: #91969F
  font-size: 15px

.social
  @include flex(20px)

.agreement
  color: #91969F
  font-size: 15px
  text-decoration: underline
