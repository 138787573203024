.table
  width: 100%
  border-collapse: collapse
  font-size: 14px
  text-align: left

  thead
    background: #FAFAFA
    text-align: center
    text-transform: uppercase

    th
      position: relative
      font-weight: 400

      &:not(:last-child)::after
        content: ""
        position: absolute
        top: 0
        bottom: 0
        right: 0
        height: 22px
        border-left: 1px solid #EBEBEB
        margin: auto

  tr
    border: solid #EBEBEB
    border-width: 1px 0

  th,
  td
    padding: 16px
