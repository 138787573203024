.breadcrumb
  grid-column: 1 / -1

  &_audit
    color: #61666F
    font-size: 18px

  &_profile
    color: #8C8C8C
    font-size: 14px

  &__item
    display: inline

    &:not(:last-child)::after
      content: " / "
