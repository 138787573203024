@use "../mixins" as *

.menu
  @include grid(25px)
  font-size: 18px

  &_small
    gap: 20px
    color: #2E3A59
    font-size: 16px
