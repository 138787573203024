@use "../mixins" as *

.dialog
  width: 440px
  max-width: 100%
  border: 0
  border-radius: 8px
  padding: 0

  &__content
    @include grid(30px)
    color: #2E3A59
    text-align: center
    padding: 60px 20px 30px

  &__title
    color: black

  &__form
    @include grid(20px)
    text-align: left

  &__field
    @include grid(8px)

  &__error
    display: none
    font-size: 12px

    .invalid &
      display: block

  &__label
    color: #9BABC5
    font-size: 14px
    font-weight: 500

  &__input
    width: 100%
    background: #F0F2F5
    border: 0
    border-radius: 5px
    color: inherit
    font: inherit
    padding: 15px

    .invalid &
      border-left: 4px solid #F25252

  &__link
    color: #1890FF
    cursor: pointer
