@use "../mixins" as *

:disabled
  opacity: 0.25

.hidden
  display: none !important

.h2
  font-size: 22px
  font-weight: 700

.h3
  font-size: 16px
  font-weight: 700
  margin-bottom: 25px

.highlight
  &_red
    color: #FF3222

  &_green
    color: #25D366

  &_blue
    color: #0471FF

  &_orange
    color: #FC7F12

  &_trust
    background: #12D646
    border-radius: 6px
    color: white
    font-weight: 700
    padding: 4px

.dim
  color: #61666F

.link
  color: #1879F8
  cursor: pointer

.separator
  height: 1px
  background: #EBEBEB

.icon-button
  width: 24px
  height: 24px
  background-color: transparent
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  cursor: pointer
  flex-shrink: 0

.expand
  @include flex(12px, center)
  cursor: pointer

  &::after
    @include flex
    content: url(../images/arrow_down.svg)

  &--expanded
    &::after
      rotate: 0.5turn

.action
  @include flex(10px, center)
  cursor: pointer

  &:hover
    color: #FF3222

.info
  @include flex(20px, center)

  &__title
    color: #61666F

.share-icon
  width: 30px
  height: 30px
  background: #F4F7FF
  border-radius: 50%
  padding: 6px

  img
    width: 100%

  &:hover
    background: #FF3222

    img
      filter: invert(1)

.list
  list-style-position: inside
  list-style-type: disc
  margin-bottom: 25px
  padding-left: 8px

  &_ordered
    list-style-type: decimal

.icon-row
  @include flex(10px, center)

.image-aside
  @include flex(30px, center)
  margin: 30px 0

  @include mobile
    flex-direction: column

.status
  &_ok
    --icon: url(../images/status-ok.svg)

  &_warn
    --icon: url(../images/status-warn.svg)

  &_error
    --icon: url(../images/status-error.svg)
    --color: #FF3222

  &__title
    @include flex(10px, center)
    color: var(--color)

    &::before
      @include flex
      width: var(--icon-size)
      height: var(--icon-size)
      content: var(--icon)

    &_small
      --icon-size: 16px

.indicator
  width: 12px
  height: 12px
  border-radius: 50%

  &_ok
    background: #6DCC17

  &_warn
    background: #EBA415

  &_error
    background: #FF5656

.table-wrapper
  overflow-x: auto

.signup-blur
  filter: blur(4px)

.loading
  @include flex(8px)

  &__circle
    width: 16px
    height: 16px
    animation: 1.5s var(--delay) infinite loading
    background: #111827
    border-radius: 50%

    @for $i from 1 through 3
      &:nth-child(#{$i})
        --delay: #{0.5s * ($i - 1)}

@keyframes loading
  0%, 50%
    background: #111827
  25%
    background: #FF3222
