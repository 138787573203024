*, ::before, ::after
  box-sizing: border-box

html,
body
  height: 100%

body
  background: #F0F4F8
  font-family: "Ubuntu", sans-serif
  margin: 0

a
  color: inherit
  text-decoration: none

button
  background: none
  font: inherit
  border: 0
  padding: 0

input[type="number"]
  appearance: textfield

input::-webkit-inner-spin-button
  display: none

img
  display: block

h1,
h2,
h3,
h4
  margin: 0

ul,
ol
  list-style: none
  margin: 0
  padding: 0

p
  margin-top: 0

p:last-child
  margin-bottom: 0
