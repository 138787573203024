@use "../mixins" as *

.audit
  @include grid(30px 15px)
  max-width: 1700px
  margin: 0 auto
  padding: 0 40px

  @include desktop
    grid-template-columns: 343px 1fr

  @include mobile
    padding: 0 20px

  &__column
    @include grid(30px)
    align-content: start

    &_main
      grid-template-rows: auto 1fr auto

  &__section
    @include grid(25px)
    align-items: start
    background: white
    border-radius: 6px
    padding: 30px

    @include mobile
      padding: 20px

    &_image
      @include desktop
        grid-template-columns: 250px 1fr

      @include tablet
        grid-template-columns: 150px 1fr

    &-title
      font-weight: 400
      font-size: 18px

      @include not-mobile
        grid-column: 1 / -1

  &__menu
    padding: 20px

  &__side-image
    width: 100%

    @include mobile
      display: none

  &__block-title
    font-weight: 500

  &__content
    @include grid(20px)

  &__item
    overflow-x: auto

  &__summary
    @include grid(10px)
    position: relative
    background: #F8F9FC
    border-radius: 8px
    padding: 20px 40px 20px 25px
    cursor: pointer

    @include mobile
      padding-left: 20px
      padding-right: 30px

    &::after
      @include flex
      position: absolute
      right: 25px
      content: url(../images/arrow_down.svg)
      align-self: center

      @include mobile
        right: 20px

    [open] &
      background: #E7F0FA

      &::after
        rotate: 180deg

  &__details
    padding: 25px 30px

    @include mobile
      padding: 20px 0

  &__list
    @include flex(44px, wrap)

    &-item
      width: 208px

  &__favicon
    height: 50px

  &__thumb
    width: 180px

.result
  @include grid(30px 40px)

  @include desktop
    grid-template-columns: 2fr 2fr 1.5fr

    &__title
      grid-column: 1 / 3

  &__share
    justify-self: end
    color: #61666F
    font-family: "Cygre", sans-serif

  &__thumb
    width: 100%

  &__rating
    font-size: 20px

  &__score
    font-family: "Cygre", sans-serif
    font-size: 35px
    font-weight: 700

  &__details
    @include grid(20px)
    margin: 20px 0

  &__actions
    @include flex(20px, wrap, center)

  &__siteinfo
    @include grid(20px)
    align-self: center
    font-size: 18px

.snippet
  &__name
    color: #1265D1

  &__content
    @include grid(25px)
    margin: 20px 0

    &_mobile
      max-width: 336px
      background: #F8F9FC
      border: 1px solid #61666F
      border-radius: 5px
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1)
      padding: 15px

  &__url
    font-size: 14px

  &__title
    color: #1A0DAB
    font-weight: 500

.tagcloud
  @include flex(0 10px, wrap, center)
  max-width: 600px
  line-height: 1
  margin: 30px 0

  li
    &:nth-child(2n)
      color: #1265D1

    &:nth-child(2n+1)
      color: #08CD71

    &[data-weight="1"]
      font-size: 16px

    &[data-weight="2"]
      font-size: 18px

    &[data-weight="3"]
      font-size: 20px

    &[data-weight="4"]
      font-size: 25px

    &[data-weight="5"]
      font-size: 48px
      font-weight: 700

    &[data-weight="6"]
      font-size: 56px
      font-weight: 700

.speed
  @include grid(25px 50px)
  align-items: center

  @include mobile
    justify-items: center
    text-align: center

  @include not-mobile
    grid-template-columns: 260px 1fr

  &__speedometer
    width: 100%
    max-width: 260px
    grid-row: 1 / 4

  &__result
    font-size: 20px

  &__score
    font-size: 35px
    font-weight: 700

.optimization
  &__stats
    @include flex(10px, wrap)
    justify-content: center

    @include desktop
      justify-content: space-between

  &__item
    @include flex(20px, center)
    width: 100%
    max-width: 250px
    background: #F0F4F8
    border-radius: 10px
    padding: 15px

    @include mobile
      flex-direction: column

  &__number
    width: 75px
    color: #701FF3
    font-size: 44px
    font-weight: 700
    text-align: center

  &__megaindex
    @include flex(10px, wrap, center)
    color: #61666F
    font-family: "Cygre", sans-serif
    margin-top: 10px

.chart
  width: 100%
  max-width: 860px

  &__legend
    width: 20px
    height: 20px
    background: #FAEBEA
    border: 1px solid #D8A9A6
    border-radius: 50%

.recommendation
  &__list
    @include grid(20px)
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr))
    align-items: end

  &__progress
    font-size: 20px
    font-weight: 700
    margin-right: 10px
