@use "../mixins" as *

.button
  @include flex(5px, inline, center)
  height: 50px
  justify-content: center
  background: white
  border-radius: 6px
  color: black
  cursor: pointer
  font-size: 14px
  font-weight: 700
  letter-spacing: 5%
  text-align: center
  text-transform: uppercase
  padding: 15px 30px
  flex-shrink: 0

  &_arrow
    --arrow-icon: url(../images/right-black.svg)

    &::after
      @include flex
      content: var(--arrow-icon)

  &_accent
    --arrow-icon: url(../images/right-white.svg)
    background: #FF3222
    color: white

  &_gray
    background: #E5E2E2

  &_blue
    background: #1265D1
    color: white

  &_outline
    background: none
    border: 1px solid #FF3222

  &_more
    justify-self: start
    background: none
    border: 1px solid #FFEBE9
    font-weight: 400
    text-transform: none
