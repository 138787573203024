@use "../mixins" as *

.profile
  @include desktop
    @include grid
    grid-template: "sidebar header" 80px "sidebar main" / 270px auto

  &__sidebar
    @include grid(70px)
    width: 270px
    height: 100%
    grid-area: sidebar
    align-content: start
    background: white
    padding: 25px 30px

    @include not-desktop
      position: fixed
      translate: -100% 0
      transition: translate 0.3s, box-shadow 0.3s
      z-index: 99

    @include mobile
      width: 100%

  &__header
    @include flex(32px, center)
    height: 80px
    grid-area: header
    background: white
    border-bottom: 1px solid #EBEBEB
    padding: 24px 36px

  &__main
    @include grid(30px)
    grid-area: main
    align-content: start
    font-size: 18px
    padding: 20px

    @include desktop
      padding-right: 50px

  &__heading
    @include flex(20px, center, wrap)
    justify-content: space-between

    h1
      font-size: 22px
      font-weight: 400

  &__title
    justify-content: space-between
    font-size: 22px
    font-weight: 500

  &__search
    @include flex(15px, center)
    background: white
    border-radius: 5px
    padding-left: 15px

    &-input
      width: 100%
      border: 0
      outline: 0
      font: inherit

  &__section
    @include grid(30px)
    background: white
    border-radius: 10px
    padding: 30px

    @include mobile
      padding: 20px

  &__expand
    @include grid(20px)
    background: #F8F9FC
    border-radius: 8px
    padding: 20px

  &__more
    font-size: 16px

  &__checklist
    @include grid(15px 30px)
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr))

    @include desktop
      grid-template-columns: repeat(auto-fit, 250px)
      justify-content: space-between

    &-item
      @include flex(15px, center)
      justify-content: space-between

.sidebar
  &--open
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2)
    translate: 0

  &__top
    @include flex(0, center)
    justify-content: space-between

  &__logo
    height: 23px

  &__close
    background-image: url(../images/close.svg)

    @include desktop
      display: none

.site
  &__overview
    @include grid(40px)
    font-size: 18px

    @include not-desktop
      gap: 20px

    @include desktop
      grid-template-columns: auto 1fr 1fr

    @include tablet
      grid-template-columns: auto auto auto

  &__name
    @include grid(20px)

  &__rating
    @include flex(10px 20px, center)
    font-family: "Cygre", sans-serif
    font-size: 20px

    @include not-desktop
      align-items: stretch
      flex-direction: column

  &__row
    @include flex(15px, center)
    width: 100%
    justify-content: space-between

  &__more
    background-image: url(../images/menu-dots.svg)

  &__runcheck
    color: #FF3222
    font-family: "Cygre", sans-serif
    font-size: 20px

.learn
  gap: 20px

  &__progress
    max-width: 300px

  &__tasks
    @include grid(20px)

  &__task
    --icon: url(../images/bolt.svg)

    &[open]
      --bg-color: #F8F9FC

    &_done
      --icon: url(../images/done.svg)
      --bg-color: #A9FFBF

      &[open]
        --bg-color: #A9FFBF

  &__summary
    @include flex(20px, center)
    background: var(--bg-color)
    cursor: pointer
    padding: 12px 60px 12px 20px

    @include mobile
      padding-right: 20px

    &::before
      @include flex
      content: var(--icon)

    &::after
      @include flex
      content: url(../images/arrow_down.svg)

      [open] &
        rotate: 180deg

  &__title
    flex: 1

  &__content
    @include flex(45px, center)
    padding: 20px

    @include mobile
      flex-direction: column
      gap: 20px
      padding: 20px 0

  &__description
    @include grid(15px)

  &__links
    @include flex(15px, wrap)

  &__link
    color: #1265D1
    font-weight: 500
    text-decoration: underline 1px

  &__complete
    @include grid(15px)
    text-align: center
